.cta-btn {
	text-decoration: none;
	color: black;
	background-color: #afffd9;
	box-shadow: 2px 2px 0px #afffd9;
	border-radius: 3px;
	transition: 0.3s ease;
	font-size: 1.5em;
	width: fit-content;
	display: flex;
	border: 1px solid #58d39a;
	cursor: pointer;

	span {
		padding: 5px 20px;
		border-right: none;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		width: 170px;
		text-align: center;
	}

	.whatsapp {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0px 10px;
		background-color: #58d39a;
		border: 1px solid #58d39a;
		border-left: none;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		color: white;
	}

	&:hover {
		box-shadow: 4px 4px 0px #afffd9;
	}

	@media (max-width: 768px) {
		font-size: 1.5em;
		margin: auto;
	}
}
